/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react"

import Layout from "components/layout"
import Seo from "components/seo"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"
import { AppContext } from "components/Context/AppContext"
import mapProductsFormAnalytics from "utils/mapProductsFormAnalytics"
import ProductFormPass from "components/Products/ProductFormPass"

import {
  ProductModal,
  ProductInfo,
  ProductGallery,
  ProductSpecificationShort,
  ProductSummary,
  ProductNav,
  ProductDescription,
  ProductSpecification,
  ProductOpinions,
  ProductRecentlyReviewed,
  ProductCartConfirmation,
} from "components/Product"

const Product = ({ pageContext }) => {
  const { data, allViewedProducts } = pageContext
  const title = data.name
  const [cart] = useContext(AppContext)

  const [modal, setModal] = useState(false)
  const [gallery, setGallery] = useState(false)
  const [cartConfirmation, setCartConfirmation] = useState(false)
  const [isLaptopCat, setIsLaptopCat] = useState(true)
  const [isAddedToCart, setIsAddedToCart] = useState(false)
  const [filteredIsLaptop, setFilteredIsLaptop] = useState([])
  const [pass, setPass] = useState(null)

  const passData = {
    pass,
    setPass,
  }

  const productData = {
    title,
    modal,
    setModal,
    gallery,
    setGallery,
    cartConfirmation,
    setCartConfirmation,
    isLaptopCat,
    isAddedToCart,
    setIsAddedToCart,
  }

  useEffect(() => {
    const isBrowser = typeof window !== "undefined"

    setFilteredIsLaptop(
      data.productCategories.nodes.filter(item => item.slug.includes("laptopy"))
    )

    if (isBrowser) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        ecommerce: {
          detail: {
            products: mapProductsFormAnalytics({ products: [data] }),
          },
        },
      })
      window.fbq("track", "ViewContent", {
        content_name: data.name,
        content_ids: [data.databaseId],
        content_type: "product",
      })
    }
  }, [
    data.productCategories.nodes,
    isAddedToCart,
    cart,
    data.databaseId,
    data.name,
    data.id,
    data.price,
  ])

  useEffect(() => {
    filteredIsLaptop.length > 0 && setIsLaptopCat(true)
  }, [filteredIsLaptop])

  useEffect(() => {
    const localStoragePass = localStorage.getItem(
      `memtech-private-product-${data?.id}`
    )
    localStoragePass === data?.acfProduct?.privateCategoryPassword &&
      setPass(localStoragePass)
  }, [])

  return (
    <Layout>
      <Seo
        title={data.seo.title ? data.seo.title : title}
        description={data.seo.metaDesc && data.seo.metaDesc}
        noIndex
      />

      <Breadcrumbs
        className="breadcrumbs--product"
        childOf="Produkty-prywatne"
        title={title}
      />

      {pass === data?.acfProduct?.privateCategoryPassword ? (
        <>
          <ProductInfo {...productData} title={title} data={data} />

          <section>
            <div className="container-fluid">
              <div className="row">
                <div className="col-xl-6 col-md-7">
                  <ProductGallery {...productData} data={data} />
                </div>

                <div className="col-xl-6 col-md-5">
                  <div className="row flex-column-reverse flex-xl-row">
                    <div className="col-xl-6">
                      <ProductSpecificationShort {...productData} data={data} />
                    </div>
                    <div className="col-xl-6">
                      <ProductSummary {...productData} data={data} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <ProductNav />

          <span id="description" />
          <ProductDescription data={data} />

          <span id="specification" />
          <ProductSpecification {...productData} data={data} />

          <span id="opinions" />
          <ProductOpinions data={data} />

          <ProductRecentlyReviewed
            {...productData}
            data={data}
            allViewedProducts={allViewedProducts}
          />

          {modal && <ProductModal {...productData} />}
          {cartConfirmation && <ProductCartConfirmation {...productData} />}
        </>
      ) : (
        <ProductFormPass
          {...passData}
          productID={data.id}
          productPass={data?.acfProduct?.privateCategoryPassword}
        />
      )}
    </Layout>
  )
}

export default Product
